(function ($, Drupal, window, document, undefined) {
    Drupal.behaviors.agir = {
        attach: function(context, settings) {
            //Sous menu version desktop
            $(".header .navigation .menu-name-main-menu > .menu > .menu__item.is-expanded ").on("mouseenter mouseleave", function(e) {
                e.preventDefault();
                // var $currentLink = $(this);
                // var $currentLi = $currentLink.parent();
                // var $submenu = $currentLi.find("> .menu");


                var $currentLi = $(this);
                var $submenu = $currentLi.find("> .menu");

                $currentLi.toggleClass('toggled');
                $submenu.toggleClass('open');
            });

            //Language switcher
            $("#block-choosit-language-switcher-choosit-language-switcher .active, #block-choosit-language-switcher-choosit-language-switcher--2 .active").on('click', function(e) {
                e.preventDefault();
                var $blockSwitcher = $(this).parent(".block");

                if(!$blockSwitcher.hasClass('open')) {
                    $(document).on("click", closeLanguageSwitcher);
                }

                $blockSwitcher.toggleClass('open');
            });
            var closeLanguageSwitcher = function(event){
                // if the target is a descendent of .header__contact__infos do nothing
                if($(event.target).is("#block-choosit-language-switcher-choosit-language-switcher .active, #block-choosit-language-switcher-choosit-language-switcher--2 .active")){
                    return;
                }

                var $languageSwitcher = $("#block-choosit-language-switcher-choosit-language-switcher, #block-choosit-language-switcher-choosit-language-switcher--2");

                if($languageSwitcher.hasClass('open')) {
                    $languageSwitcher.removeClass('open');
                    // remove event closeHeaderContact from document
                    $("#block-choosit-language-switcher-choosit-language-switcher .active, #block-choosit-language-switcher-choosit-language-switcher--2 .active").off("click", closeLanguageSwitcher);
                }
            };

            //Menu version responsive
            $(".menu__responsive__toggle").on('click', function() {
                var $this = $(this);
                var $menuResp = $(".responsive-navigation");

                $this.toggleClass("toggle");
                $menuResp.toggleClass('toggled');
            });
            $(".header .responsive-navigation .menu-name-main-menu > .menu > .menu__item.is-expanded > .menu__link").on("click", function(e) {
                e.preventDefault();
                var $currentLink = $(this);
                var $currentLi = $currentLink.parent(".menu__item");
                var $submenu = $currentLi.find("> .menu");

                $currentLi.toggleClass('toggled');
                $submenu.toggleClass('open');
            });

            //Bloc contact header
            $(".contact__display__infos").on('click', function(e) {
                e.preventDefault();
                e.stopPropagation();
                $(".header .contact .header__contact__infos").addClass('toggled');
                // add event closeHeaderContact from document
                $(".contact__display__infos").on("click", closeHeaderContact);
            });
            var closeHeaderContact = function(event){
                // if the target is a descendent of .header__contact__infos do nothing
                if($(event.target).is(".header .contact .header__contact__infos, .header .contact .header__contact__infos .infos__line, .header .contact .header__contact__infos, .header .contact .header__contact__infos .infos__line")){
                    return;
                }


                var $contactInfo = $(".header .contact .header__contact__infos");
                if($contactInfo.hasClass('toggled')) {
                    $contactInfo.removeClass('toggled');
                    // remove event closeHeaderContact from document
                    $(".contact__display__infos").off("click", closeHeaderContact);
                }
            };
            //$(document).on("click", closeHeaderContact);

            //Form header search
            $(".search_form_bien_immo .form-item.form-type-checkboxes > label").on('click', function(e){
                e.stopPropagation();
                var $this = $(this);
                var $formItem = $this.parent(".form-item");
                var $checkboxGroup = $formItem.find(".form-checkboxes");

                if(!$formItem.hasClass('toggled') && !$checkboxGroup.hasClass('open')) {
                    $(document).on("click", closeHeaderFormSelectCheckboxes);
                }

                var $currentOppened = $(".search_form_bien_immo .form-item.form-type-checkboxes.toggled");
                if($currentOppened.length) {
                    var $open = $currentOppened.find('.form-checkboxes.open');
                    $open.removeClass('open');
                    $currentOppened.removeClass('toggled');
                } else {
                    $formItem.toggleClass('toggled');
                    $checkboxGroup.toggleClass('open');
                }
            });
            var closeHeaderFormSelectCheckboxes = function(event){
                // if the target is a descendent of .header__contact__infos do nothing
                if($(event.target).is(".search_form_bien_immo .form-item.form-type-checkboxes .form-checkboxes, .search_form_bien_immo .form-item.form-type-checkboxes .form-checkboxes *")){
                    return;
                }

                var $currentOppened = $(".search_form_bien_immo .form-item.form-type-checkboxes.toggled");
                if($currentOppened.length) {
                    var $open = $currentOppened.find('.form-checkboxes.open');
                    $open.removeClass('open');
                    $currentOppened.removeClass('toggled');
                    // remove event closeHeaderContact from document
                    $(document).off("click", closeHeaderFormSelectCheckboxes);
                }
            };

            //slider programme home
            $(".programme_homepage .view-content").owlCarousel({
                margin: 30,
                // items : 3,
                loop: true,
                stagePadding: 30,
                nav: true,
                navText: ["",""],
                dots: false,
                responsiveClass:true,
                responsiveRefreshRate: 10,
                responsive: {
                    // breakpoint from 0 up
                    0:{
                        items : 1
                    },
                    // breakpoint from 680 up
                    680:{
                        items : 2
                    },
                    // breakpoint from 1200 up
                    1024: {
                        items : 3
                    },
                },
            });

            //slider actu home
            $(".actualites--front .view-content").owlCarousel({
                margin: 0,
                items : 1,
                loop: false,
                stagePadding: 0,
                nav: false,
                navText: ["",""],
                dots: true,
                responsiveRefreshRate: 10,
                responsive: {
                    // breakpoint from 0 up
                    0:{
                        items : 1
                    },
                    // breakpoint from 680 up
                    680:{
                        items : 2,
                        margin: 20,
                    },
                    // breakpoint from 1070 up
                    1070: {
                        items : 1
                    },
                },
            });


            //slider visuel fiche programme
            $(".page-programme-detail .galerie .field-items").owlCarousel({
                margin: 0,
                items : 1,
                loop: false,
                stagePadding: 0,
                nav: true,
                navText: ["",""],
                dots: true,
            });

            var programmeFancyHrefArray = new Array();
            var programmeFancyHrefCount = 0;
            $(".page-programme-detail .galerie img").each(function () {
                programmeFancyHrefArray[programmeFancyHrefCount] = $(this).data("href");
                programmeFancyHrefCount++;
            });

            $(".page-programme-detail .galerie .visuel_fancybox_link").click(function(e) {
                e.preventDefault();

                $.fancybox(programmeFancyHrefArray,{
                    'padding'		: 0,
                    'title'   		: '',
                    'transitionIn'	: 'elastic',
                    'transitionOut'	: 'elastic',
                    'speedIn'		: 600,
                    'speedOut'		: 200,
                    'overlay'       : true,
                    'type'          : 'image',
                    'tpl'           : {
                        closeBtn : '<a title="' + Drupal.settings.fancybox_close + '" class="fancybox-item fancybox-close" href="javascript:;"></a>',
                        next     : '<a title="' + Drupal.settings.fancybox_next + '" class="fancybox-nav fancybox-next" href="javascript:;"><span></span></a>',
                        prev     : '<a title="' + Drupal.settings.fancybox_previous + '" class="fancybox-nav fancybox-prev" href="javascript:;"><span></span></a>'
                    }
                });
            });

            //slider visuel fiche programme reportage
            $(".page-programme-detail #reportages .accordion .accordion-item .field-name-field-visuels .field-items").owlCarousel({
                margin: 25,
                items : 1,
                loop: false,
                stagePadding: 0,
                nav: true,
                navText: ["",""],
                dots: false,
                responsiveRefreshRate: 10,
                responsive: {
                    // breakpoint from 0 up
                    0:{
                        items : 1
                    },
                    // breakpoint from 800 up
                    680:{
                        items : 2,
                        margin: 15,
                    },
                    // breakpoint from 1200 up
                    1200: {
                        items : 3
                    },
                },
            });

            var programmeReportageFancyHrefArray = new Array();
            var programmeReportageFancyHrefCount = 0;
            $(".page-programme-detail #reportages .accordion .accordion-item .field-name-field-visuels .visuel_fancybox_link").each(function () {
                programmeReportageFancyHrefArray[programmeReportageFancyHrefCount] = $(this).data("href");
                programmeReportageFancyHrefCount++;
            });

            $(".page-programme-detail #reportages .accordion .accordion-item .field-name-field-visuels .visuel_fancybox_link").click(function(e) {
                e.preventDefault();

                $.fancybox(programmeReportageFancyHrefArray,{
                    'padding'		: 0,
                    'title'   		: '',
                    'transitionIn'	: 'elastic',
                    'transitionOut'	: 'elastic',
                    'speedIn'		: 600,
                    'speedOut'		: 200,
                    'overlay'       : true,
                    'type'          : 'image',
                    'tpl'           : {
                        closeBtn : '<a title="' + Drupal.settings.fancybox_close + '" class="fancybox-item fancybox-close" href="javascript:;"></a>',
                        next     : '<a title="' + Drupal.settings.fancybox_next + '" class="fancybox-nav fancybox-next" href="javascript:;"><span></span></a>',
                        prev     : '<a title="' + Drupal.settings.fancybox_previous + '" class="fancybox-nav fancybox-prev" href="javascript:;"><span></span></a>'
                    }
                });
            });

            //slider visuel fiche autre programme
            $(".autre-programmes .wrapper-autre-programmes .view-content").owlCarousel({
                margin: 30,
                items : 1,
                loop: false,
                stagePadding: 30,
                nav: true,
                navText: ["",""],
                dots: false,
                responsiveRefreshRate: 10,
                responsive: {
                    // breakpoint from 0 up
                    0:{
                        items : 1,
                        margin: 0,
                        stagePadding: 0,
                    },
                    // breakpoint from 800 up
                    680:{
                        items : 2,
                    },
                    // breakpoint from 1200 up
                    1200: {
                        items : 3
                    },
                },
            });


            //display share button
            $("#share-bouton .share").on('click', function(e) {
                e.preventDefault();
                var $shareButton = $("#share-bouton");
                var $toolBox = $shareButton.children('.addthis_toolbox');

                $toolBox.toggleClass('open');
                $shareButton.toggleClass('toggled');
            });


            //Form page search
            $(".view-trouver-un-logement .form-item.form-type-checkboxes > label").on('click', function(e){
                e.stopPropagation();
                var $this = $(this);
                var $formItem = $this.parent(".form-item");
                var $checkboxGroup = $formItem.find(".form-checkboxes");

                if(!$formItem.hasClass('toggled') && !$checkboxGroup.hasClass('open')) {
                    $(document).on("click", closeSearchFormSelectCheckboxes);
                }

                var $currentOppened = $(".view-trouver-un-logement .form-item.form-type-checkboxes.toggled");
                if($currentOppened.length) {
                    var $open = $currentOppened.find('.form-checkboxes.open');
                    $open.removeClass('open');
                    $currentOppened.removeClass('toggled');
                } else {
                    $formItem.toggleClass('toggled');
                    $checkboxGroup.toggleClass('open');
                }
            });
            var closeSearchFormSelectCheckboxes = function(event){
                // if the target is a descendent of .header__contact__infos do nothing
                if($(event.target).is(".view-trouver-un-logement .form-item.form-type-checkboxes .form-checkboxes, .view-trouver-un-logement .form-item.form-type-checkboxes .form-checkboxes *")){
                    return;
                }

                var $currentOppened = $(".view-trouver-un-logement .form-item.form-type-checkboxes.toggled");
                if($currentOppened.length) {
                    var $open = $currentOppened.find('.form-checkboxes.open');
                    $open.removeClass('open');
                    $currentOppened.removeClass('toggled');
                    // remove event closeHeaderContact from document
                    $(document).off("click", closeSearchFormSelectCheckboxes);
                }
            };



            //isotope liste realisation
            var $pageRealisationListGrid = $('.view-realisations .realisation__list.isotope .view-content');
            if($pageRealisationListGrid.length) {
                $pageRealisationListGrid.isotope({
                    itemSelector: '.views-row',
                    percentPosition: true,
                    masonry: {
                        columnWidth: '.grid-sizer',
                        gutter: '.gutter-sizer'
                    }
                });
            }

            //Form page mon compte espace pro
            $(".page__espace__pro .header__form .form-item.form-type-checkboxes > label").on('click', function(e){
                e.stopPropagation();
                var $this = $(this);
                var $formItem = $this.parent(".form-item");
                var $checkboxGroup = $formItem.find(".form-checkboxes");

                if(!$formItem.hasClass('toggled') && !$checkboxGroup.hasClass('open')) {
                    $(document).on("click", closeMonCompteFormSelectCheckboxes);
                }

                var $currentOppened = $(".page__espace__pro .header__form .form-item.form-type-checkboxes.toggled");
                if($currentOppened.length) {
                    var $open = $currentOppened.find('.form-checkboxes.open');
                    $open.removeClass('open');
                    $currentOppened.removeClass('toggled');
                } else {
                    $formItem.toggleClass('toggled');
                    $checkboxGroup.toggleClass('open');
                }

            });
            var closeMonCompteFormSelectCheckboxes = function(event){
                // if the target is a descendent of .header__contact__infos do nothing
                if($(event.target).is(".page__espace__pro .header__form .form-item.form-type-checkboxes.toggled .form-checkboxes, .page__espace__pro .header__form .form-item.form-type-checkboxes.toggled .form-checkboxes *")){
                    return;
                }

                var $currentOppened = $(".page__espace__pro .header__form .form-item.form-type-checkboxes.toggled");
                if($currentOppened.length) {
                    var $open = $currentOppened.find('.form-checkboxes.open');
                    $open.removeClass('open');
                    $currentOppened.removeClass('toggled');
                    // remove event closeHeaderContact from document
                    $(document).off("click", closeMonCompteFormSelectCheckboxes);
                }
            };

            /* Readonly */

            if( $('.bdtpicker').length ){
                $('.bdtpicker').attr('readonly', "readonly");
            }

            $('[data-toggle="tooltip"]').tooltip();

            //hover liste de programme
            $(".view-les-programmes, .view-trouver-un-logement").on("mouseenter mouseleave", ".views-row", function() {
                var $row = $(this);
                var $overlay = $row.find('.views-field-field-visuels .overlay');

                $row.toggleClass('hovered');
                $overlay.toggleClass('show');
            });

            $(document).foundation();

            //isotope liste page dans page sommaire
            var $pageSommaireListGrid = $('.page_standard_sommaire.isotope .list-summary-items');
            if($pageSommaireListGrid.length) {
                $pageSommaireListGrid.isotope({
                    itemSelector: '.choosit-summary-item',
                    percentPosition: true,
                    masonry: {
                        columnWidth: '.grid-sizer',
                        gutter: '.gutter-sizer'
                    }
                });
            }

            $(".link-to-scroll").on('click', function(e) {
                e.stopPropagation();
                e.preventDefault();

                var target = $(this).attr("href");
                $.scrollTo($(target), 900);
            });
        }
    };

})(jQuery, Drupal, this, this.document);
